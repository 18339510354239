import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46')
];

export const server_loads = [0,2,4];

export const dictionary = {
		"/(app)": [5,[2]],
		"/auth/login": [~44,[4]],
		"/(app)/campaign": [6,[2]],
		"/(app)/campaign/booking": [10,[2]],
		"/(app)/campaign/booking/[id=uuid]": [11,[2]],
		"/(app)/campaign/category": [12,[2]],
		"/(app)/campaign/category/create": [~14,[2]],
		"/(app)/campaign/category/[id=integer]": [~13,[2]],
		"/(app)/campaign/create": [~15,[2]],
		"/(app)/campaign/[campaignId=integer]": [7,[2,3]],
		"/(app)/campaign/[campaignId=integer]/edit": [~8,[2,3]],
		"/(app)/campaign/[campaignId=integer]/edit/page/[pageId=integer]": [~9,[2,3]],
		"/(app)/facility": [16,[2]],
		"/(app)/facility/[id=integer]": [17,[2]],
		"/(app)/facility/[id=integer]/campaign": [18,[2]],
		"/(app)/facility/[id=integer]/customer": [19,[2]],
		"/(app)/facility/[id=integer]/lead-settings": [20,[2]],
		"/(app)/facility/[id=integer]/tariffs": [21,[2]],
		"/(app)/facility/[id]/lead-settings": [22,[2]],
		"/(app)/features": [23,[2]],
		"/(app)/lead/data-table": [25,[2]],
		"/(app)/lead/data-table/[id]": [26,[2]],
		"/(app)/lead/[[id]]": [~24,[2]],
		"/(app)/member": [27,[2]],
		"/(app)/member/signup": [28,[2]],
		"/(app)/member/signup/[id=integer]": [29,[2]],
		"/(app)/next-steps": [30,[2]],
		"/(app)/reporting": [31,[2]],
		"/(app)/schedule": [32,[2]],
		"/(app)/settings": [~33,[2]],
		"/(app)/settings/[categoryKey]": [~34,[2]],
		"/(app)/staff": [35,[2]],
		"/(app)/staff/create": [~43,[2]],
		"/(app)/staff/[id]": [36,[2]],
		"/(app)/staff/[id]/contract": [~37,[2]],
		"/(app)/staff/[id]/contract/create": [~38,[2]],
		"/(app)/staff/[id]/contract/edit": [~39,[2]],
		"/(app)/staff/[id]/edit": [~40,[2]],
		"/(app)/staff/[id]/shift": [41,[2]],
		"/(app)/staff/[id]/shift/edit": [~42,[2]],
		"/test/bits-ui": [45],
		"/test/daisyui": [46]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';